import {
  OtherCamper,
  getSelectedCamper,
} from "./../components/payment/OtherCamper";
import { PaymentChoice } from "./../components/payment/PaymentChoice";
import { useState, useEffect } from "react";
import supabase from "../config/supabase";
import { Button } from "primereact/button";
import { PaymentType } from "../components/payment/PaymentType";
import { getCurrentUserParams } from "@db/currentUser";
import { getPricingFromCurrentUser, getPricingUserParams } from "@db/pricing";
import {
  getPaymentTypesFromUser,
  getRemainingAmountToPayFromUser,
} from "@db/payments";
import { getUrlLocationOrigin } from "../utils/locationUrl.js";
import CheckoutBox from "../components/payment/CheckoutBox";
import { LoadingData } from "@components/status/Loading.js";
import NotAvailable from "@components/status/NotAvailable.js";
import { getAdminSettings } from "@db/admin.js";
import {getIsDeadlinePassed} from "../db/campInfo.js"

export default function Cart() {
  const [priceAmount, setPriceAmount] = useState(null);
  const [priceName, setPriceName] = useState(null);
  const [uuidOfUserPaidFor, setUuidOfUserPaidFor] = useState(null);
  const [donationAmount, setDonationAmount] = useState(null);
  const [stayAnoymous, setStayAnoymous] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null); // Add this line
  const [currentUserName, setCurrentUserName] = useState(null); // Add this line
  const [currentUserEmail, setCurrentUserEmail] = useState(null); // Add this line
  const [currentUserObject, setCurrentUserObject] = useState({
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    is_registered: null,
    price_type: null,
    price: null,
  });
  const [fullPrice, setFullPrice] = useState(null);
  const [isCurrentUserRegistered, setIsCurrentUserRegistered] = useState(true);
  const [isDiscountCodeValid, setIsDiscountCodeValid] = useState(false);
  const [discountCode, setDiscountCode] = useState(" ");
  const [isDisabledPaymentType, setIsDisabledPaymentType] = useState(true);
  const [paymentTypeArray, setPaymentTypeArray] = useState([]);
  const [payButtonDisabled, setPayButtonDisabled] = useState(true);
  const [pricingId, setPricingId] = useState(null);
  const [firstPaymentPrice, setFirstPaymentPrice] = useState(null);
  const [secondPaymentPrice, setSecondPaymentPrice] = useState(null);
  const [remainPrice, setRemainPrice] = useState(null);
  const [loadingPage, setLoadingPage] = useState(true);
  const [accommodation, setAccommodation] = useState(null);
  const [disablePayment, setDisablePayment] = useState(false);
  const [isDeadlinePassed, setIsDeadlinePassed] = useState(false);

  const handleNetsPayment = async () => {
    if (
      priceAmount === null ||
      priceName === null ||
      paymentTypeChoice === ""
    ) {
      return alert("Please fill out all the fields");
    }

    const finalPrice = (priceAmount * 100).toFixed(0); // Round to 0 decimal places
    const urlOrigin = getUrlLocationOrigin();
    const anonymouseRef = stayAnoymous === true ? "Anonymous" : "Not anonymous";
    const discountRef =
      isDiscountCodeValid === true ? discountCode : "No discount";
    const { data, error } = await supabase.functions.invoke("payment", {
      body: {
        checkout: {
          integrationType: "HostedPaymentPage",
          returnUrl: urlOrigin + "/checkout",
          termsUrl: urlOrigin + "/terms-of-service",
          charge: true,
        },
        order: {
          items: [
            {
              reference: paymentTypeChoice,
              name: uuidOfUserPaidFor + " " + camperChoice,
              quantity: 1,
              unit: "pcs",
              unitPrice: finalPrice,
              grossTotalAmount: finalPrice,
              netTotalAmount: finalPrice,
            },
            {
              reference: anonymouseRef + "-" + discountRef,
              name: currentUserId + " " + currentUserName,
              quantity: 1,
              unit: "pcs",
              unitPrice: 0,
              grossTotalAmount: 0,
              netTotalAmount: 0,
            },
          ],
          amount: finalPrice,
          currency: "DKK",
          reference: priceName,
        },
        notifications: {
          webhooks: [
            {
              eventName: "payment.checkout.completed",
              url: process.env.REACT_APP_PAYMENT_WEBHOOK_URL,
            },
          ],
        },
        paymentInfo: {
          paidForUserId: uuidOfUserPaidFor,
          paidByUserId: currentUserId,
          priceType: priceName,
          paymentType: paymentTypeChoice,
          amount: priceAmount,
          isDiscount: isDiscountCodeValid,
          discountCode: discountCode,
          payeeName: camperChoice,
          payerName: currentUserName,
          isAnonymous: stayAnoymous,
          created_at: new Date().toISOString(),
        },
      },
    });

    if (error) {
      console.log("There was an error: ", error);
      alert("There was an error: ", error);
      return;
    }
    if (!data.hostedPaymentPageUrl || data.hostedPaymentPageUrl === undefined)
      return alert("There was an error, could not get payment site");
    window.location.href = data.hostedPaymentPageUrl;
  };

  const [choice, setChoice] = useState("Not Selected");

  const handleChoice = (value) => {
    setChoice(value);
  };

  useEffect(() => {
    const setInitialValues = async () => {
      const disable = await getAdminSettings(["DISABLE_PAYMENTS"]);
      setDisablePayment(disable.DISABLE_PAYMENTS.bool);

      const user = await getCurrentUserParams(
        "id, is_registered, first_name , last_name, email, accommodation"
      );
      if (!user.is_registered) setIsCurrentUserRegistered(true);
      else setIsCurrentUserRegistered(false);
      setCurrentUserId(user.id);
      setCurrentUserName(`${user.first_name} ${user.last_name}`);
      setCurrentUserEmail(user.email);
      setAccommodation(user.accommodation);


      const hasDeadlineExpired = await checkDeadline();
      setIsDeadlinePassed(hasDeadlineExpired);
      
      setTimeout(() => {
        setLoadingPage(false);
      }, 750);
    };


    backButton();
    setInitialValues();
    getIsDeadlinePassed();
  }, []);

  const backButton = () => {
    setChoice("Not Selected");
    setCamperChoice(null);
    setPaymentTypeChoice("");
    setUuidOfUserPaidFor(undefined);
    setPriceName(null);
    setPriceAmount(null);
    setIsDisabledPaymentType(true);
    setStayAnoymous(false);
    setPaymentTypeArray([]);
    setAccommodation(null);
  };

  const [paymentTypeChoice, setPaymentTypeChoice] = useState("");

  const [camperChoice, setCamperChoice] = useState(null);

  const onChangerOtherCamper = async () => {
    let c = await getSelectedCamper();
    let p = await getPricingUserParams(c.id);
    let _pricing = p.pricing;
    
    if(await checkDeadline()) _pricing.price = Math.round(_pricing.price * 1.053);
    
    if (c !== null) {
      setCamperChoice(c.name);
      setUuidOfUserPaidFor(c.id);
    }
    if (p !== null) {
      setPriceName(_pricing.name);
      setPricingId(_pricing.id);
      setAccommodation(p.accommodation);
    }
    if (c !== null && _pricing !== null) calculatingPrice(_pricing.price, c.id);
  };


  const checkDeadline = async () => {
    const hasDeadlineExpired = await getIsDeadlinePassed();
    setIsDeadlinePassed(hasDeadlineExpired);
    if(hasDeadlineExpired) return true
    return false
  }


  const calculatingPrice = async (price, uuid) => {
    setPriceAmount(null);
    setFullPrice(price);
    const amountLeftToPay = await getRemainingAmountToPayFromUser(price, uuid);
    setFirstPaymentPrice(Number((amountLeftToPay / 3).toFixed(2)));
    setSecondPaymentPrice(Number((amountLeftToPay / 2).toFixed(2)));
    setRemainPrice(Number(amountLeftToPay.toFixed(2)));
  };

  useEffect(() => {
    const getCurrentUsersPaymentDetail = async () => {
      const user = await getCurrentUserParams(
        "id, first_name, last_name, email, is_registered, accommodation"
      );
      const pricing = await getPricingFromCurrentUser();
      setCurrentUserObject(user);
      setPriceName(pricing.name);
      setCamperChoice(`${user.first_name} ${user.last_name}`); // Use `user` directly
      setUuidOfUserPaidFor(user.id);
      calculatingPrice(pricing.price, user.id);
      setAccommodation(user.accommodation);
    };

    if (choice === "Pay for myself") {
      getCurrentUsersPaymentDetail();
    } else if (choice === "Not Selected" || choice === "" || choice === null) {
      backButton();
    }
  }, [choice]);

  useEffect(() => {
    const gettingPaymentType = async () => {
      setPaymentTypeChoice(null);
      if (uuidOfUserPaidFor === undefined || uuidOfUserPaidFor === null) {
        setIsDisabledPaymentType(true);
        return;
      } else {
        const respArray = await getPaymentTypesFromUser(uuidOfUserPaidFor);
        setPaymentTypeArray(respArray);
        setIsDisabledPaymentType(false);
      }
    };
    gettingPaymentType();
  }, [camperChoice]);

  useEffect(() => {
    if (paymentTypeChoice === null) return setPayButtonDisabled(true);
    setPayButtonDisabled(false);
    if (paymentTypeChoice === "Full payment") {
      setPriceAmount(fullPrice);
    } else if (paymentTypeChoice === "First payment") {
      setPriceAmount(firstPaymentPrice);
    } else if (paymentTypeChoice === "Second payment") {
      setPriceAmount(secondPaymentPrice);
    } else if (
      paymentTypeChoice === "Remaining amount" ||
      paymentTypeChoice === "Third payment"
    ) {
      setPriceAmount(remainPrice);
    }
  }, [paymentTypeChoice]);

  const pBlack = "p-black";
  const pResult = "p-black p-result";

  const footer = (
    <>
      <Button
        label='Pay'
        onClick={handleNetsPayment}
        disabled={payButtonDisabled}
        className='prime paymentButton'
      />
    </>
  );

  const notes = (
    <>
      <p className='payment-notes'>
        <strong>Payments are non-refundable. </strong> 
      </p>
    </>
  );

  return (
    <>
      {disablePayment ? (
        <NotAvailable
          title='Payments are not available at the moment'
          // message='Will open again on Saturday 13th of April 2025'
        />
      ) : choice === "Not Selected" || choice === "" || choice === null ? (
        <PaymentChoice
          handleChoice={handleChoice}
          isCurrentUserRegistered={isCurrentUserRegistered}
        />
      ) : disablePayment !== null ? (
        <div className='payment-div'>
          <div className='align-horizontal-payment'>
            {choice === "Pay for myself" && (
              <>
                <PaymentType
                  choice={paymentTypeChoice}
                  setChoice={setPaymentTypeChoice}
                  backButton={backButton}
                  paymentTypeArray={paymentTypeArray}
                  isDisabled={false}
                  placeholder='Select a payment type'
                  isDeadlinePassed={isDeadlinePassed}
                />
                {notes}
              </>
            )}

            {choice === "Pay for someone else" && (
              <>
                <OtherCamper
                  onChangerOtherCamper={onChangerOtherCamper}
                  stayAnoymous={stayAnoymous}
                  setStayAnoymous={setStayAnoymous}
                  currentUserId={currentUserId}
                />
                <PaymentType
                  choice={paymentTypeChoice}
                  setChoice={setPaymentTypeChoice}
                  backButton={backButton}
                  isDisabled={isDisabledPaymentType}
                  paymentTypeArray={paymentTypeArray}
                  placeholder={
                    isDisabledPaymentType
                      ? "First select a person above"
                      : "Select a payment type"
                  }
                  pricingId={pricingId}
                  isDeadlinePassed={isDeadlinePassed}
                />
                {notes}
              </>
            )}
          </div>

          {/* CheckoutBox included here, inside the conditional rendering structure */}
          <CheckoutBox
            footer={footer}
            pBlack={pBlack}
            choice={choice}
            camperChoice={camperChoice}
            priceName={priceName}
            paymentTypeChoice={paymentTypeChoice}
            currentUserEmail={currentUserEmail}
            stayAnoymous={stayAnoymous}
            priceAmount={priceAmount}
            pResult={pResult}
            donationAmount={donationAmount}
            accommodation={accommodation}
            isDeadlinePassed={isDeadlinePassed}
          />
        </div>
      ) : (
        <LoadingData />
      )
      }
    </>
  );
}
