import supabase from "../config/supabase.js";

export const getTotalRegistered = async () => {
  const { count, error } = await supabase
    .from("users")
    .select("id", { count: "exact" }) // Request the count
    .eq("is_registered", true);

  if (error) {
    console.error("Error fetching total registered users", error);
    return null;
  }
  return count;
};

export const getTotalSignUps = async () => {
  const { count, error } = await supabase
    .from("users")
    .select("id", { count: "exact" }) // Request the count
    .eq("is_attending", true);

  if (error) {
    console.error("Error fetching total sign ups", error);
    return null;
  }
  return count;
};
