import React, { useEffect, useState } from "react";
import { CountriesList } from "../components/CountriesList";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import supabase from "../config/supabase";
import "../styles/form.css";
import { useNavigate } from "react-router-dom";
import { setPricingId } from "@utils/pricingCalculate.js";
import { getPricingInfoById } from "@db/pricing.js";
import SelectAccomodation from "@components/form/SelectAccomodation";
import SelectTransport from "@components/form/SelectTransport";
import SelectDays from "@/components/form/SelectDays";
import SelectSize from "@/components/form/SelectSize";
import SelectMembership from "@/components/form/SelectMembership";
import SelectMissions from "@/components/form/SelectMissions";
import SelectGender from "@/components/form/SelectGender";
import SelectBirthday from "@/components/form/SelectBirthday.js";
import RedeemCode from "@/components/form/RedeemCode.js";
import { calculateAgeByDate } from "@utils/getAge.js";
import ConfirmBox from "@components/form/ConfirmBox";
import { uuidv4Generator } from "@utils/generator.js";
import { DiscordWebhookSendForm } from "@api/discord/discordWebhooks.js";
import { insertUser } from "@/db/users.js";
import {
  checkIfCurrentUserExistsInTable,
  getIdsFromCurrentUserByParentId,
} from "@/db/currentUser.js";
import { getTotalAddCamperAllowed } from "@utils/globalVariables";
import { checkVariablesForm } from "@utils/checkVariables.js";
import { LoadingModule } from "@components/status/Loading";
import { getUrlLocationOrigin } from "@utils/locationUrl.js";
import { getAdminSettingsBool } from "@db/admin.js";
import { LoadingData } from "@components/status/Loading.js";
import GoogleForm from "@components/form/GoogleForm.js";

export default function Form({ addCamperPage }) {
  const [uuid, setUuid] = useState(null);
  const [currentUserUuid, setCurrentUserUuid] = useState(null);
  const [email, setEmail] = useState("");
  const [successBoxVisible, setSuccessBoxVisible] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const nav = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [birthday, setBirthday] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [church, setChurch] = useState("");
  const [otherChurch] = useState("");
  const [member, setMember] = useState(null);
  const [shirtSize, setShirtSize] = useState("");
  const [selectedDates, setSelectedDates] = useState("");
  const [arrivalTransportation, setArrivalTransportation] = useState("");
  const [departureTransportation, setDepartureTransportation] = useState("");
  const [accommodation, setAccommodation] = useState("");
  const [isSenior, setIsSenior] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedGroupFormation, setCheckedGroupFormation] = useState(false);
  const [isCodeRedeemed, setIsCodeRedeemed] = useState(false);
  const [redeemCodeDetails, setRedeemCodeDetails] = useState(null);
  const [age, setAge] = useState(null);
  const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false);
  const [pricingInfo, setPricingInfo] = useState({});
  const [loadingBoxVisible, setLoadingBoxVisible] = useState(false);
  const [disableNewUser, setDisableNewUser] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showGoogleForm, setShowGoogleForm] = useState(false);

  useEffect(() => {
    const fetchIfNewUserAllowed = async () => {
      const disable = await getAdminSettingsBool("DISABLE_NEW_USER");
      setDisableNewUser(disable);
      setTimeout(() => setLoading(false), 1000);
    };
    const checkIfUserIsAllowedToFillForm = async () => {
      const response = await checkIfCurrentUserExistsInTable();
      if (addCamperPage) {
        const totalAddedCamper = await getIdsFromCurrentUserByParentId();
        const totalAllowed = getTotalAddCamperAllowed();
        if (totalAddedCamper.length >= totalAllowed) {
          nav("/profile");
          return;
        }
      } else if (!addCamperPage && response === true) {
        nav("/profile");
        return;
      }
    };

    const getUuidEmail = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUuid(user.id);
      setEmail(user.email);
      if (
        user.user_metadata.full_name !== null &&
        user.user_metadata.full_name !== undefined &&
        user.user_metadata.full_name !== "" &&
        !addCamperPage
      ) {
        const name = user.user_metadata.full_name.split(" ");
        const lastName = name.pop(); // Remove and store the last name
        const firstNames = name.join(" "); // Join the remaining names as first names
        setFirstName(firstNames);
        setLastName(lastName);
      }

      if (!addCamperPage) {
        setCurrentUserUuid(user.id);
      } else {
        setCurrentUserUuid(uuidv4Generator());
      }
    };

    if (addCamperPage) localStorage.setItem("activeTab", 2);
    else localStorage.setItem("activeTab", 0);
    fetchIfNewUserAllowed();
    checkIfUserIsAllowedToFillForm();
    getUuidEmail();
  }, []);

  function formatName(name) {
    // Trim any leading or trailing whitespace
    name = name.trim();

    // Remove unwanted characters
    name = name.replace(/[<>'"&\\]/g, "");

    // Replace multiple consecutive spaces with a single space
    name = name.replace(/\s+/g, " ");

    // Split the name into an array of names
    let names = name.split(" ");

    // Capitalize the first letter of each name and lowercase the rest
    names = names.map(function (name) {
      return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    });

    // Join the names back together with a single space
    return names.join(" ");
  }

  useEffect(() => {
    const calculateAge = async () => {
      if (birthday !== "") {
        setAge(await calculateAgeByDate(birthday));
      }
    };
    calculateAge();
  }, [birthday]);

  let selectedChurch = "";
  
  const submit = async (isConfirmed) => {
    const errors = checkVariablesForm({
      first_name: firstName,
      last_name: lastName,
      birthday: birthday,
      gender: gender,
      country: country,
      church: church,
      member: member,
      size: shirtSize,
      dates: selectedDates,
      arrival_transportation: arrivalTransportation,
      departure_transportation: departureTransportation,
      accommodation: accommodation,
      check: checked,
      checkGroupFormation: checkedGroupFormation,
    });
    if (errors.length > 0) {
      setErrorMessages(errors);
      return;
    } else {
      setErrorMessages([]);
    }
    const logInfo = () => {
      console.log("Email: " + email);
      console.log("First Name: " + firstName);
      console.log("Last Name: " + lastName);
      console.log("Phone: " + phone);
      console.log("Birthday: " + birthday);
      console.log("Gender: " + gender);
      console.log("Country: " + country);
      console.log("Church: " + church);
      console.log("Member: " + member);
      console.log("Shirt Size: " + shirtSize);
      console.log("Selected Dates: " + selectedDates);
      console.log("Arrival Transportation: " + arrivalTransportation);
      console.log("Departure Transportation: " + departureTransportation);
      console.log("Accommodation: " + accommodation);
      console.log("Checked: " + checked);
    };

    // logInfo();
    if (church === "Other") {
      if (otherChurch === "") {
        console.log("form error church");
        return;
      } else {
        selectedChurch = otherChurch;
        setChurch(otherChurch);
      }
    } else {
      selectedChurch = church;
    }

    let pricingId = await setPricingId(
      birthday,
      isSenior,
      country,
      accommodation,
      isCodeRedeemed,
      redeemCodeDetails
    );

    const pricing = await getPricingInfoById(pricingId);
    setPricingInfo(pricing);

    const firsrNameFormatted = formatName(firstName);
    const lastNameFormatted = formatName(lastName);

    setFirstName(firsrNameFormatted);
    setLastName(lastNameFormatted);

    if (isConfirmed) {
      insertFormToDatabase(
        firsrNameFormatted,
        lastNameFormatted,
        selectedChurch,
        pricingId,
        pricing
      );
    } else {
      setIsConfirmBoxVisible(!isConfirmBoxVisible);
    }
  };

  const insertFormToDatabase = async (
    _firstname,
    _lastname,
    selectedChurch,
    pricingId,
    pricing
  ) => {
    setIsConfirmBoxVisible(!isConfirmBoxVisible);

    let parentId = null;
    if (addCamperPage) {
      parentId = uuid;
    }

    let hasBed = null;
    if (pricing.reserved_bed === true) {
      //For complimentary pricing
      hasBed = true;
    } else if (pricing.includes_bed) {
      //For people that has bed included in the price, but set it to false, cause they haven't paid yet
      hasBed = false;
    }

    const formUserObject = {
      id: currentUserUuid,
      email: email,
      first_name: _firstname,
      last_name: _lastname,
      phone: phone,
      birthday: birthday,
      gender: gender,
      country: country,
      church_mission: selectedChurch,
      size: shirtSize,
      member: member,
      dates: selectedDates,
      arrival_transportation: arrivalTransportation,
      departure_transportation: departureTransportation,
      accommodation: accommodation,
      pricing_id: pricingId,
      parent_id: parentId,
      is_registered: pricing.price === 0,
      registered_at: pricing.price === 0 ? new Date() : null,
      has_bed: hasBed, // go to line 239
    };
    setLoadingBoxVisible(true);
    const response = await insertUser(formUserObject);

    if (redeemCodeDetails?.price === 0 && response) {
      const { error } = await supabase.functions.invoke("send-receipt", {
        body: {
          uuid: currentUserUuid,
        },
      });

      if (error) {
        console.log("error in sendReceipt");
        console.log(error);
        return;
      }
    }

    if (response.statusCode === 400) {
      console.log(response.error);
      setErrorMessages("Something went wrong!, please try again later.");
      console.log("error");
      return;
    }
    await DiscordWebhookSendForm(formUserObject);
    setTimeout(() => {
      setErrorMessages([]);
      setSuccessBoxVisible(true);
    }, 2500);
  };

  const goToGroupFormationForm = () => {
    window.scrollTo(0, 0);
    setShowGoogleForm(true);
  };

  return loading ? (
    <LoadingData text='Loading form page...' />
  ) : disableNewUser ? (
    <div className='form-screen-container'>
      <div className='form-container'>
        <div className='form'>
          <span className='tag-red normal-text'>
            The form is closed, we do not allow new users to sign up at the
            moment.
          </span>
          <Button
            className='prime'
            label='Go to home page'
            onClick={() => nav("/")}
          />
        </div>
      </div>
    </div>
  ) : showGoogleForm ? (
    <>
      <GoogleForm
        uuid={currentUserUuid}
        firstName={firstName}
        lastName={lastName}
        birthday={birthday}
        country={country}
        gender={gender}
        submit={submit}
      />
      {loadingBoxVisible && <LoadingModule message='Submitting...' />}
      <Dialog
        header='Sucessfully filled up the form '
        visible={successBoxVisible}
        onHide={() => {
          setSuccessBoxVisible(false);
          nav("/profile");
        }}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <p className='succesful-text'>
          You can now see {addCamperPage ? "their" : "your"} details under{" "}
          {addCamperPage ? "add camper" : "profile"} page.
        </p>
        <Button
          label='Go to profile page'
          icon='pi pi-check'
          onClick={() => {
            nav("/profile");
            setSuccessBoxVisible(false);
          }}
        />
      </Dialog>
    </>
  ) : (
    <>
      <div className='form-screen-container'>
        <div className='form-container'>
          <h1 className='form-title'>Form</h1>
          <div className='form'>
            <div className='input-group success'>
              {addCamperPage ? null : (
                <>
                  <label htmlFor='email'>Email</label>
                  <input
                    type='email'
                    name='email'
                    id='email'
                    value={email}
                    readOnly
                  ></input>
                </>
              )}
            </div>
            <InputText
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder='First name'
            />
            <InputText
              placeholder='Last name'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <InputText
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              placeholder='Phone nr include country code (optional)'
            />
            <div className='div-birthday'>
              <SelectBirthday onSelectChange={setBirthday} />
            </div>
            <div className='center-items'>
              <SelectGender onSelectChange={setGender} />
            </div>
            <CountriesList onSelectChange={setCountry} />
            <SelectMissions onSelectChange={setChurch} />
            <SelectMembership
              onSelectChange={setMember}
              addCamperPage={addCamperPage}
            />
            <SelectSize onSelectChange={setShirtSize} age={age} />
            <SelectDays onSelectChange={setSelectedDates} />
            <div className='center'>
              <p className='member-boolean-txt'> Arrival </p>
              <SelectTransport onSelectChange={setArrivalTransportation} />
            </div>
            <div className='center'>
              <p className='member-boolean-txt'> Depature </p>
              <SelectTransport onSelectChange={setDepartureTransportation} />
            </div>
            <SelectAccomodation onSelectChange={setAccommodation} />
            <RedeemCode
              onSelectChange={setIsCodeRedeemed}
              onSelectCodeDetails={setRedeemCodeDetails}
            />
            {age >= 60 ? (
              <>
                <div className='col-12 checkbox-div'>
                  <Checkbox
                    onChange={(e) => setIsSenior(e.checked)}
                    checked={isSenior}
                  ></Checkbox>
                  <label
                    htmlFor='cb1'
                    className='p-checkbox-label checkbox-text'
                  >
                    I am a senior and retired from work
                  </label>
                </div>
              </>
            ) : null}
            <div className='col-12 checkbox-div'>
              <Checkbox
                onChange={(e) => setCheckedGroupFormation(e.checked)}
                checked={checkedGroupFormation}
              ></Checkbox>
              <label htmlFor='cb1' className='p-checkbox-label checkbox-text'>
                I agree to share my data for the group formation algorithm for
                the church camp <strong>(Required)</strong>{" "}
                <a
                  className='navigate-text-left'
                  target='_blank'
                  href={`${getUrlLocationOrigin()}/group-formation`}
                  onClick={() => {}}
                >
                  Learn more
                </a>
              </label>
            </div>
            <div className='col-12 checkbox-div'>
              <Checkbox
                onChange={(e) => setChecked(e.checked)}
                checked={checked}
              ></Checkbox>
              <label htmlFor='cb1' className='p-checkbox-label checkbox-text'>
                {" "}
                I acknowledge that payments are non-refundable and accept{" "}
                <a
                  className='navigate-text-left'
                  target='_blank'
                  href={`${getUrlLocationOrigin()}/terms-of-service`}
                >
                  the terms of service
                </a>{" "}
                and{" "}
                <a
                  className='navigate-text-left'
                  target='_blank'
                  href={`${getUrlLocationOrigin()}/privacy-policy`}
                >
                  privacy policy
                </a>{" "}
                of CBMBC and its Church Missions
              </label>
            </div>
            <div className='error-messages'>
              {errorMessages.length > 0 &&
                errorMessages.map((msg, index) => (
                  <React.Fragment key={index}>
                    <span className='error-msg'>{msg}</span>
                    <br />
                  </React.Fragment>
                ))}
            </div>

            <Button
              label='Submit'
              className='form-sub-button p-button-rounded'
              onClick={() => submit(false)}
            />
          </div>
        </div>
      </div>
      <ConfirmBox
        visible={isConfirmBoxVisible}
        first_name={firstName}
        last_name={lastName}
        phone={phone}
        birthday={birthday}
        gender={gender}
        country={country}
        church={church}
        member={member}
        shirt_size={shirtSize}
        arrival_transportation={arrivalTransportation}
        departure_transportation={departureTransportation}
        accommodation={accommodation}
        selected_dates={selectedDates}
        pricingInfo={pricingInfo}
        submit={goToGroupFormationForm}
        onHide={() => setIsConfirmBoxVisible(false)}
      />
    </>
  );
}
