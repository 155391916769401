import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { SelectUserData } from "../../config/DataHandler";
import "../../styles/payment.css";
import {
  getPaymentsForUser,
  getPaymentsByUser,
  getPaymentsFromUserByFromParentId,
} from "@/db/payments.js";
import { getPricingFromCurrentUser } from "@/db/pricing.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatLocalDateTime } from "@utils/dateTime.js";
import { Accordion, AccordionTab } from "primereact/accordion";
import {
  getCurrentUser,
  getUsersFromCurrentUserByParentId,
} from "@/db/currentUser.js";
import { registerTag } from "../rows/tags.js";
import ExportExcelButton from "@components/buttons/ExportExcelButton.js";
import ExportPDFButton from "@components/buttons/ExportPDFButton.js";

export default function PaymentInfo() {
  const [priceTagName, setPriceTagName] = useState("Loading...");
  const [priceTagNumber, setPriceTagNumber] = useState(null);
  const [otherCamperData, setOtherCamperData] = useState([]);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentTotalPaid, setPaymentTotalPaid] = useState(0);
  const [paymentByUserData, setPaymentByUserData] = useState([]);
  const [usersRegistered, setUsersRegistered] = useState(null);
  const [paymentsFromChildId, setPaymentsFromChildId] = useState([]);
  const [currentUserName, setCurrentUserName] = useState("");

  const nav = useNavigate();

  const handleClick = (event) => {
    event.preventDefault(); // Prevent the default behavior of anchor tag
    nav("/pricing");
  };

  //initialization
  useEffect(() => {
    localStorage.setItem("activeTab", 1);
    const getUsersData = async () => {
      const data = await getPaymentsForUser();
      const sumTotal = sumTotalAmount(data);

      setPaymentAmount(sumTotal);

      const pricingData = await getPricingFromCurrentUser();

      setTimeout(() => {
        setPriceTagName(pricingData.name);
        setPriceTagNumber(pricingData.price);
      }, 1000);

      setTimeout(() => {
        setPaymentTotalPaid(ProgressBarCalcValue(pricingData.price, sumTotal));
      }, 800);
    };
    const getPaymentsFromOtherUsers = async () => {
      setOtherCamperData(await getUsersFromCurrentUserByParentId());
    };

    const getPaymentsByUserData = async () => {
      try {
        const [paymentData, currentUserData, dataFromOtherCampers] =
          await Promise.all([
            getPaymentsByUser(),
            getCurrentUser(),
            getPaymentsFromUserByFromParentId(),
          ]);

        setPaymentsFromChildId(dataFromOtherCampers);
        setUsersRegistered(currentUserData.is_registered);
        setCurrentUserName(
          currentUserData.first_name + " " + currentUserData.last_name
        );

        if (paymentData.length === 0) {
          setPaymentByUserData([]);
        }

        const dataListArrayPaymentByUser = [];

        const processData = (d) => {
          const name = `${d.paidFor.first_name} ${d.paidFor.last_name}`;
          let namePaidby = "Error";

          if (
            d.paidBy.first_name === currentUserData.first_name &&
            d.paidBy.last_name === currentUserData.last_name
          ) {
            namePaidby = "You";
          } else if (d.is_anonymous) {
            namePaidby = "Anonymous";
            d.paidBy.last_name = "";
          } else {
            namePaidby = `${d.paidBy.first_name} ${d.paidBy.last_name}`;
          }

          const date = formatLocalDateTime(d.created_at);

          return {
            id: d.id,
            name,
            created_at: date,
            amount: `${d.amount} DKK`,
            type: d.type,
            pricing: d.pricing.name,
            paid_by: namePaidby,
            payment_method: d.payment_method,
            is_anonymous: d.is_anonymous ? "Yes" : "No",
          };
        };

        if (paymentData.length !== null) {
          paymentData.forEach((d) => {
            dataListArrayPaymentByUser.push(processData(d));
          });
        }

        if (dataFromOtherCampers !== null) {
          dataFromOtherCampers.forEach((d) => {
            if (!dataListArrayPaymentByUser.some((item) => item.id === d.id)) {
              dataListArrayPaymentByUser.push(processData(d));
            }
          });
        }

        setPaymentByUserData(dataListArrayPaymentByUser);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getUsersData();
    getPaymentsByUserData();
    getPaymentsFromOtherUsers();
  }, []);

  const sumTotalAmount = (data) => {
    let sum = 0;
    data.map((d) => {
      sum += d.amount;
    });
    return sum;
  };

  const getTotalAmount = (id) => {
    let sum = 0;
    paymentsFromChildId.map((d) => {
      if (d.paidFor.id === id) {
        sum += d.amount;
      }
    });
    return sum;
  };

  //-----Payment progress bar-----//
  const valueTemplate = (value) => {
    value = (Math.round(value * 100) / 100).toFixed(2);
    return <React.Fragment>{value} %</React.Fragment>;
  };

  const ProgressBarCalcValue = (_priceTagNumber, _paymentAmount) => {
    if (_priceTagNumber === 0) {
      return 100;
    } else if ((_paymentAmount / _priceTagNumber) * 100 > 100) {
      return 100;
    } else {
      return (_paymentAmount / _priceTagNumber) * 100;
    }
  };

  const columns = [
    { field: "name", header: "Name", sortable: true },
    { field: "created_at", header: "Date", sortable: true },
    { field: "amount", header: "Amount", sortable: true },
    { field: "type", header: "Type", sortable: true },
    { field: "pricing", header: "Pricing" },
    { field: "paid_by", header: "Paid by" },
    { field: "is_anonymous", header: "Anonymous" },
    { field: "payment_method", header: "Payment method" },
    { field: "id", header: "ID" },
  ];

  const exportColumns = columns.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportPdf = (exportColumns, data, fileName) => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, data);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  return (
    <>
      <p>Here you can see all your payments and payment fee status.</p>
      <p>
        <a
          href='/pricing' // Optionally, you can keep the href for SEO or fallback
          style={{ paddingBottom: "10px" }}
          className='navigate-text'
          onClick={handleClick} // Call handleClick on click event
        >
          See all the different pricing.
        </a>
      </p>
      <div className='row-profile-left'>
        <Button
          className='prime'
          label='Go to payment page'
          onClick={() => nav("/payment")}
        />
      </div>
      <Divider />
      <p className='profile-info'>{currentUserName}</p>
      <p className='profile-info'>
        {usersRegistered === null
          ? "..."
          : usersRegistered
          ? registerTag(true)
          : registerTag(false)}
      </p>
      <ProgressBar
        value={paymentTotalPaid}
        displayValueTemplate={valueTemplate}
        style={{ width: "100%" }}
      ></ProgressBar>
      <p>
        {priceTagNumber === null
          ? "Calculating"
          : priceTagNumber === 0
          ? "Free"
          : paymentAmount + "/" + priceTagNumber + " DKK"}
      </p>
      <p className='profile-info'>Price type: {priceTagName}</p>
      <p className='profile-info'>
        Price:{" "}
        {priceTagNumber === null
          ? "Calculating..."
          : priceTagNumber === 0
          ? "Free"
          : priceTagNumber + " DKK"}
      </p>
      {otherCamperData.map((camper, index) => {
        return (
          <div key={index}>
            <Divider />
            <p>{camper.first_name + " " + camper.last_name} </p>
            <p>
              {camper.is_registered ? registerTag(true) : registerTag(false)}
            </p>
            <ProgressBar
              value={ProgressBarCalcValue(
                camper.pricing.price,
                getTotalAmount(camper.id)
              )}
              displayValueTemplate={valueTemplate}
              style={{ width: "100%" }}
            ></ProgressBar>
            <p>
              {camper.pricing.price === 0
                ? "Free"
                : getTotalAmount(camper.id) > 0
                ? `${getTotalAmount(camper.id)}/${camper.pricing.price} DKK`
                : `0/${camper.pricing.price} DKK`}
            </p>
            <p className='profile-info'>Price type: {camper.pricing.name}</p>
            <p className='profile-info'>Price: {camper.pricing.price} DKK</p>
            <div className='row-profile-left'>
              <p className='profile-info'></p>
            </div>
          </div>
        );
      })}
      {/* End of other campers tab  --------------------------------------*/}
      <Divider />
      <Accordion activeIndex={0}>
        <AccordionTab header='Payment history'>
          {paymentByUserData.length === 0 ? (
            <p>No payments has been made</p>
          ) : (
            <>
              <ExportPDFButton
                value={paymentByUserData}
                fileName='my-payment-history'
              />
              <ExportExcelButton
                value={paymentByUserData}
                fileName='my-payment-history'
              />
              <DataTable
                value={paymentByUserData}
                size='small'
                tableStyle={{ minWidth: "100%" }}
                stripedRows
                scrollable
                scrollHeight='400px'
              >
                {columns.map((col, i) => {
                  return (
                    <Column
                      sortable={col.sortable}
                      key={i}
                      field={col.field}
                      header={col.header}
                    />
                  );
                })}
              </DataTable>
              {/* <p>Total: {totalPaid(paymentByUserData)}</p> */}
            </>
          )}
        </AccordionTab>
      </Accordion>
    </>
  );
}
