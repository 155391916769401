import React from "react";
import "../styles/pages.css";
import "../styles/backgrounds.css";

export default function GroupFormation() {
  const bottomMargin = {
    margin: "0 0 10px 0",
  };

  const removeBottomMargin = {
    marginBottom: "0",
  };

  const removeTopAndBottomMargin = {
    marginBottom: "0",
    marginTop: "0",
  };

  return (
    <>
      <section className='page-text-white bg-black-gradient-grid page-container'>
        <div className='text-left box-width page-box'>
          <h1>Group Formation Algorithm</h1>
          <h2>About</h2>
          <p>
            The group formation algorithm is part of a bachelor project aimed at
            creating fair and balanced teams for the annual church camp. The
            camp registration team currently spends hours manually assigning
            campers to teams. This prototype aims to streamline the process by
            using an algorithm to ensure fairness. To do this, we collect data
            on camper in each categories such as Bible knowledge, musical
            talent, leadership, creativity etc.
          </p>

          <p>
            To ensure diverse teams with a mix of participants from different
            missions and countries, we may introduce constraints to prevent
            certain campers from being placed together, such as siblings,
            married couples, and close friends. At the same time, we will also
            include constraints for campers who need to be together, such as a
            parent and child or new campers who feel more comfortable with
            someone they know. This helps ensure that no one feels isolated.
            These decisions can be fill on the form provided. But the camp
            committee will also review the data to ensure that the constraints
            are reasonable.
          </p>
          <h2>Privacy & Data Storage </h2>
          <p>
            We are fully committed to GDPR compliance and ensuring that your
            data is securely handled. The data will only be used for the church
            camp's group formation process and registration process. Your
            information will not be shared or sold to third parties.
          </p>

          <p>
            You can read more about how we handle your data {" "}
            <a href='/privacy-policy'>
            here.
            </a>
          </p>
          <h2>Mandatory Data</h2>
          <p>
            Signing up for the Camp requires being part of a team. Since teams
            are formed based on skill distribution, providing your data is
            mandatory. By declining to share your data, you are also choosing
            not to participate in the camp.
          </p>

          <h2>What data we need from you</h2>
          <p style={{ marginBottom: "0" }}>Fill up form:</p>
          <ul style={bottomMargin}>
            <li>Full name</li>
            <li>Birthday</li>
            <li>Country</li>
            <li>Gender</li>
          </ul>
          <p style={{ marginBottom: "0" }}>Skill level:</p>
          <ul style={bottomMargin}>
            <li> Bible knowledge</li>
            <li> Musical talent and abilities</li>
            <li> Leadership</li>
            <li> Creativity</li>
            <li> Acting</li>
          </ul>
          <p style={{ marginBottom: "0" }}>Constraints:</p>
          <ul style={bottomMargin}>
            <li>
              People they need to be with (e.g. kids needs to be with their
              parent/guardian or first timer needs to be with someone they
              know).
            </li>
            <li>
              People they don't want to be with (e.g. individuals from the same
              household such as siblings or spouses). Our goal is to help you
              meet new people, and being with someone you see every day may
              limit that opportunity.
            </li>
          </ul>

          <p>
            The skill level in each category will be determined by campers
            themselves through an additional form. The information will be
            reviewed by selected individuals to ensure it’s reasonable and not
            too uncanny. These ratings will remain strictly confidential and
            will not be shared with anyone else— consider it a secret they’ll
            take to the grave. Only the evaluators, the students working on this
            bachelor project, and the supervisor will have access to this
            information.
          </p>

          <h2>The Preliminary Problem Statement</h2>
          <p>
            We aim to develop a prototype group formation algorithm that is
            focusing on an annual church camp, where the solution is to divide
            campers into teams, ensuring that each team has a balanced
            distribution of key skills, such as leadership, Bible knowledge,
            creativity, and musical abilities. The prototype should be able to
            assign each person to a team based on the skills that have been
            defined by the camp committee.
          </p>
          <p>
            The project report will discuss the functionality of the system,
            user understanding of the process, and the role of game theory in
            team formation. Additionally, we will consider privacy aspects,
            including privacy principles and GDPR compliance.
          </p>

          <h2 style={removeBottomMargin}>Relevant Resources</h2>
          <ul>
            <li>Project report - N/A</li>
            <li>Source code - N/A</li>
          </ul>

          <h2 style={removeBottomMargin}>Contact info</h2>
          <p style={removeTopAndBottomMargin}>Students: </p>
          <ul>
            <li>
              Joshua James Calba -<a href='mailto:jcal@itu.dk'> jcal@itu.dk</a>
            </li>
            <li>
              Tan Dang - <a href='mailto:tanda@itu.dk'>tanda@itu.dk</a>
            </li>
          </ul>
          <p style={removeBottomMargin}> Supervisor: </p>
          <ul>
            <li>
              Riko Jacob - <a href='mailto:rikj@itu.dk'>rikj@itu.dk</a>
            </li>
          </ul>
          <p style={removeBottomMargin}> University: </p>
          <ul>
            <li>
              IT University of Copenhagen -{" "}
              <a href='https://www.itu.dk/' target='blank'>
                www.itu.dk
              </a>
            </li>
          </ul>
          <p style={removeBottomMargin}> Duration of the project: </p>
          <ul>
            <li> January 31 - May 15, 2025</li>
          </ul>
        </div>
      </section>
    </>
  );
}
