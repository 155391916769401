import React from "react";

export default function TermsOfService() {
  return (
    <>
      <section className='page-text-white bg-black-gradient-grid-2 page-container'>
        <div className='text-left box-width page-box'>
          <h1 className='text-h1'>Terms Of Service</h1>
          <p className='subtitle'>Last updated: February 12, 2025</p>
          <h2>1. Introduction</h2>
          <p>
            Welcome to the{" "}
            <strong>
              {" "}
              Church Family Camp of Bethel Missionary Baptist Missions of
              Europe{" "}
            </strong>{" "}
            (referred to as "Church Camp"). This website is designed to help
            users register for the camp, access essential information such as
            pricing and activities, and pay camp fees. By using this website,
            you agree to comply with these Terms of Service. Please read these
            terms carefully.
          </p>

          <h2>2. Eligibility and Registration</h2>
          <h3>2.1 General Eligibility</h3>
          <p>
            Any individual interested in attending the camp may register through
            this website. Registration requires creating an account using a
            valid email address.
          </p>

          <h3>2.2 Parent or Guardian Registration for Minors</h3>
          <p>
            For children under the age of <strong>12</strong>, a
            parent or legal guardian must complete the registration on their
            behalf. The parent or guardian is responsible for providing accurate
            information and ensuring compliance with these Terms of Service.
          </p>

          <h2>3. Purpose of the Website</h2>
          <p>This website serves the following purposes:</p>
          <ul>
            <li>
              Providing detailed information about the camp, including pricing,
              schedule, and activities
            </li>
            <li>
              Allowing users to register and manage their camp participation
            </li>
            <li>Facilitating group formation for a better camp experience</li>
            <li>Collecting and processing camp fee payments</li>
          </ul>

          <h2>4. Account Responsibilities</h2>
          <p>When registering for the camp, you agree to:</p>
          <ul>
            <li>Provide accurate and up-to-date information</li>
            <li>
              Keep your account credentials secure and not share them with
              others
            </li>
            <li>
              Notify us immediately if you suspect unauthorized access to your
              account
            </li>
          </ul>
          <p>
            We reserve the right to suspend or terminate accounts that violate
            these terms or submit false information.
          </p>

          <h2>5. Camp Fee Payment Terms</h2>
          <h3>5.1 Payment Options</h3>
          <p>
            You can choose to pay your camp fee in{" "}
            <strong>one of the following ways</strong> during registration:
          </p>
          <ul>
            <li>
              <strong>Full Payment:</strong> Pay the entire camp fee at once.
            </li>
            <li>
              <strong>Installment Plan:</strong> Pay in three installments:
              <ul>
                <li>
                  <strong>1/3 of the camp fee</strong> at the time of
                  registration
                </li>
                <li>
                  <strong>2/3 of the total fee</strong> before the next deadline
                </li>
                <li>
                  <strong>Remaining balance (3/3)</strong> before the final
                  payment deadline
                </li>
              </ul>
            </li>
          </ul>

          <h3>5.2 Installment Rules and Conditions</h3>
          <p>
            If you choose to pay in installments, each subsequent payment must
            be made on time according to the specified deadlines. As the final
            deadline approaches, the{" "}
            <strong>1/3 payment option will no longer be available</strong>, and
            you must pay the full remaining balance in one payment.
          </p>

          <h3>5.3 Bed Availability and Price Adjustment</h3>
          <p>
            <strong>Accommodations with Beds are Limited.</strong> If you select
            a pricing option that includes a bed but have not completed full
            payment, your registration will be automatically adjusted if beds
            become fully booked.
          </p>
          <p>
            <strong>Scenario for Partially Paid Registrations:</strong>
          </p>
          <ul>
            <li>
              If beds are fully booked and you have paid{" "}
              <strong>1/3 of the original camp fee</strong>, your price will be
              adjusted to the <strong>"Stay-In" option</strong> (where you must
              bring your own sleeping mat or tent).
            </li>
            <li>
              The amount you’ve already paid will be deducted from the adjusted
              "Stay-In" price, and you will only be responsible for paying the
              remaining balance of the new price.
            </li>
          </ul>

          <h3>5.4 Late Payments and Missed Deadlines</h3>
          <p>
            Failure to complete your payment on time may result in cancellation
            of your registration or adjustment to a different accommodation
            option. Please contact us immediately if you experience difficulty
            making a payment.
          </p>

          <h3>5.5 Refund Policy</h3>
          <p>
            <strong>No refunds will be issued once payment is made.</strong>{" "}
            However, if you cannot attend the camp, you may transfer your spot
            to another camper who has not yet fully paid. Contact us at{" "}
            <strong>camp.cbmbc@gmail.com</strong> to arrange a transfer.
          </p>

          <h3>5.6 Discount Deadline and Pricing</h3>
          <ul>
            <li>
              A <strong>5% discount</strong> is available for full payments made
              before the early registration deadline. After the deadline, the
              full camp fee will apply, and no further discounts will be given.
            </li>
            <li>
              <strong>Prices are non-negotiable.</strong> Special rates or
              discounts beyond those officially announced are not available.
            </li>
          </ul>

          <h2>6. Camp Registration Process</h2>
          <p>
            Once registered, your information will be used to assign you to a
            suitable group and plan your camp experience. Registration does not
            guarantee specific group assignments or room preferences, but we
            strive to accommodate reasonable requests.
          </p>

          <h2>7. Information Accuracy</h2>
          <p>
            We make every effort to ensure the information on the website is
            accurate and up to date. However, we cannot guarantee that all
            information (e.g., pricing or camp activities) will be error-free.
            We reserve the right to correct inaccuracies or update information
            at any time without prior notice.
          </p>

          <h2>8. Prohibited Activities</h2>
          <p>By using the website, you agree not to:</p>
          <ul>
            <li>Provide false or misleading information during registration</li>
            <li>
              Attempt to gain unauthorized access to the website or related
              systems
            </li>
            <li>
              Use the website for any harmful or malicious purposes (e.g.,
              hacking, spreading malware)
            </li>
          </ul>

          <h2>9. Liability Disclaimer</h2>
          <p>
            The website is provided on an <strong>"as-is"</strong> basis. We
            make no guarantees regarding availability, accuracy, or reliability.
            We are not responsible for any technical issues or interruptions
            that may affect your use of the site.
          </p>

          <h2>10. Modifications to the Terms</h2>
          <p>
            We may update these Terms of Service from time to time. Any changes
            will be posted on this page, and your continued use of the website
            constitutes acceptance of the updated terms.
          </p>

          <h2>11. Governing Law</h2>
          <p>
            These Terms of Service are governed by the laws of{" "}
            <strong>Denmark</strong>. Any disputes arising from
            these terms will be subject to the jurisdiction of the courts in{" "}
            <strong>Copenhagen Denmark</strong>.
          </p>

          <h2>12. VAT and Pricing Explanation</h2>
          <p>
            The camp fees displayed on our website{" "}
            <strong>do not include VAT</strong>. Our church camp is organized
            and facilitated entirely by volunteers, and we are not a business
            entity aiming for profit. The purpose of this camp is to bring
            people together in fellowship and strengthen our relationship with
            God.
          </p>
          <p>
            As such, we operate outside the scope of VAT regulations, and no
            additional VAT will be applied to the fees. The prices you see are
            the final amounts you are required to pay for attending the camp.
          </p>

          <h2>13. Contact Us</h2>
          <p>
            If you have any questions or concerns about these Terms of Service,
            please contact us at <strong>camp.cbmbc@gmail.com</strong>.
          </p>
        </div>
      </section>
    </>
  );
}
