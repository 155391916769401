import React, { useState, useEffect } from "react";
import GoogleFormRadioInput from "./GoogleFormRadioInput.js";
import GoogleFormInputText from "./GoogleFormInputText.js";
import { formatDateToYYYYMMDD } from "../../utils/dateTime";

export default function GoogleForm({
  uuid,
  firstName,
  lastName,
  birthday,
  country,
  gender,
  submit,
}) {
  const [formData, setFormData] = useState({});
  const [hasClickedSubmit, setHasClickedSubmit] = useState(false);

  useEffect(() => {
    // Initialize formData with predefined values

    setFormData((prevData) => ({
      ...prevData,
      "entry.1094716533": uuid || "",
      "entry.1698242187": firstName || "",
      "entry.790810882": lastName || "",
      "entry.2095258987": formatDateToYYYYMMDD(birthday) || "",
      "entry.1444359639": country || "",
      "entry.1141976931": gender || "",
    }));
  }, [uuid, firstName, lastName, birthday, country, gender]);

  const questions = [
    {
      type: "text",
      question: "UUID",
      entryId: "entry.1094716533",
      value: uuid,
      invisible: true,
    },
    {
      type: "text",
      question: "First Name",
      entryId: "entry.1698242187",
      value: firstName,
      invisible: true,
    },
    {
      type: "text",
      question: "Last Name",
      entryId: "entry.790810882",
      value: lastName,
      invisible: true,
    },
    {
      type: "text",
      question: "Birthday",
      entryId: "entry.2095258987",
      value: formatDateToYYYYMMDD(birthday),
      invisible: true,
    },
    {
      type: "text",
      question: "Country",
      entryId: "entry.1444359639",
      value: country,
      invisible: true,
    },
    {
      type: "text",
      question: "Gender",
      entryId: "entry.1141976931",
      value: gender,
      invisible: true,
    },
    {
      type: "radio",
      question: "Are you willing to be a team leader on your team?",
      entryId: "entry.1156400308",
      labels: [
        "Yes, I am willing to be a team leader",
        "No, but I can co-lead and help the team leader",
        "No",
      ],
    },
    {
      type: "radio",
      question: "Would you consider yourself a creative thinker?",
      entryId: "entry.986359478",
    },
    {
      type: "radio",
      question: "Do you consider yourself knowledgable about the Bible?",
      entryId: "entry.1029488804",
    },
    {
      type: "radio",
      question:
        "Are you physically fit and able to play in outdoor activities like outdoor games, obstacle courses, running, sports etc.?",
      entryId: "entry.1586231790",
    },
    {
      type: "radio",
      question: "Can you play the guitar, piano or keyboard well?",
      entryId: "entry.308896362",
      removeMaybe: true,
    },
    {
      type: "radio",
      question: "Have you been in a Christian camp before?",
      entryId: "entry.1415565693",
      removeMaybe: true,
    },
    {
      type: "radio",
      question: "Are you willing to take a speaking role in the Biblical drama? (Ex. main role, supporting role, narrator etc.)",
      entryId: "entry.1910356021",
    },
    {
      type: "radio",
      question:
        "Will you be able to create and design costumes and props for the drama?",
      entryId: "entry.939723869",
      removeMaybe: true,
    },
    {
      type: "text",
      question:
        "If you are entitled to be in the team with someone, please write their full name (for more than one person please separate the names by comma ',' ) e.g. kids needs to be with their parent/guardian or new camper needs to be with someone they know. <br/> <strong> NOTE: Listing someone does not guarantee you will be placed on the same team. We will only honor these requests if they are reasonable and relevant. Unreasonable requests will be removed before the group formation process, so please answer truthfully. <br/> Leave blank if none</strong>",
      entryId: "entry.1374230167",
      require: false,
    },
    {
      type: "text",
      question:
        "Please list the full names of people (separate by comma ',') who should not be on your team (e.g., spouse, siblings, roommates, people I see (almost) every day). This will help us create teams that encourage meeting new people, fostering new connections, and promoting teamwork with a more diverse group. Being on a team with familiar faces can limit these opportunities. <br/> <strong> Leave blank if none</strong>",
      entryId: "entry.186472463",
      require: false,
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    setHasClickedSubmit(true);
    e.preventDefault();
    const googleFormActionURL =
      "https://docs.google.com/forms/d/e/1FAIpQLSf0J-wFCoh4NZ_WPiA9M9J-R40CQKwKYL6sgamB1Mb5PTwf8g/formResponse";
    const form = new FormData();

    for (const [key, value] of Object.entries(formData)) {
      form.append(key, value);
    }

    fetch(googleFormActionURL, {
      method: "POST",
      mode: "no-cors",
      body: form,
    })
      .then(() => {
        submit(true);
      })
      .catch((error) => {
        console.error("Error!", error.message);
      });
  };

  return (
    <div className='google-form-container'>
      <form onSubmit={handleSubmit} className='google-form'>
        <h1>Form for The Group Formation Algorithm</h1>
        <p style={{ textAlign: "center" }}>
          Form submission for <br />
          {firstName + " " + lastName}
        </p>
        <p>
          The goal of this form is to use an algorithm for group formation,
          replacing the manual process that takes the registration team hours to
          create fair teams. The data given in this form will not be made
          public; only the camp committee, as well as the assigned students and
          supervisor of the bachelor project, will have access to it. We
          encourage you to take this form carefully as it will help us to
          organise teams smoothly and in order, ensuring a fair and well
          structured procedure reducing work load for our team.
        </p>

        <p style={{ fontWeight: "900", color: "red" }}>
          Take your time and consider your response carefully. Thank you.
        </p>

        {questions.map(
          ({
            type,
            question,
            entryId,
            value,
            removeMaybe,
            invisible,
            labels,
          }) => {
            if (type === "radio") {
              return (
                <GoogleFormRadioInput
                  key={entryId}
                  handleChange={handleChange}
                  question={question}
                  entryId={entryId}
                  removeMaybe={removeMaybe}
                  labels={labels}
                />
              );
            }

            if (type === "text") {
              return (
                <GoogleFormInputText
                  key={entryId}
                  handleChange={handleChange}
                  question={question}
                  entryId={entryId}
                  value={formData[entryId] || value || ""}
                  invisible={invisible}
                />
              );
            }

            return null;
          }
        )}

        <div className='center' style={{ display: "flex" }}>
          <button type='submit' className='google-form-button'
          disabled={hasClickedSubmit}>
            SUBMIT FORM
          </button>
        </div>
      </form>
    </div>
  );
}
